.main_contacts{
    display: flex;
    justify-content: space-between;
    width: 75%;
    height: 100%;
}
.cont_item{
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin: 0 2.5%;
    justify-content: center;
    /* width: 31%; */
}
/* .cont_item:nth-child(1){
    width: 31%;
    margin-right: 4%;
}
.cont_item:nth-child(1) .cont_text_header{
    margin-top: 30%;
}
.cont_item:nth-child(3){
    width: 31%;
    margin-left: 4%;
} */
.cont_item:nth-child(1) .cont_img{
    max-width: 80%;
}
.cont_img{
    /* height: 62%; */
    height: 7vw;
}
.cont_text_container{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.cont_text_header{
    font-family: 'Times New Roman';
    font-style: normal;
    font-weight: 400;
    font-size: 1.9vw;
    line-height: 2.5vw;
    text-align: justify;
    color: #000000;
    width: calc(100% + 1vw);
    margin-left: -0.5vw;
}
.cont_text_subheader{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.93vw;
    line-height: 1.4vw;
    text-align: justify;
    color: #000000;
    margin-top: 30px;
}
.cont_text_cont_row{
    display: flex;
    flex-grow: 1;
}
.cont_text_cont_row .cont_text_left_cont:nth-child(2){
    padding-left: 0.3vw;
}
.cont_text_left_cont{
    width: 100%;
}
.cont_text_link{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.93vw;
    line-height: 1.4vw;
    text-align: right;
    color: #000000;
}
.main_contacts1 a{
    color: #000000;
    text-decoration: none;
    font-size: 1.2vw;
}
.cont_text_text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.5vw;
    line-height: 0.7vw;
    text-align: justify;
    color: #000000;
}