canvas{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}


.defs{
    position: absolute;
    width: 400px;
    left: -9999%;
    /* left: 0;
    top: 0; */
}

img.slide{
    display: block;
    max-width: 100%;
}
img.slide.active{
    max-width: unset !important;
    display: block;
    top: 0;
    left: calc(50vw - calc(100vh / 0.56 / 2));
    /* transform: translate(calc(100vh / 0.56 / 2), 10px); */
    height: 100vh !important;
    width: calc(100vh / 0.56) !important;
    /* min-width: 100vw !important; */
    min-height: 100vh !important;
    cursor: default !important;
    z-index: 9;
    /* position: static; */
    position: fixed;
    /* width: calc(100vh / 0.56) !important; */
}

.nav_item{
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    overflow: hidden;
    width: 10vw;
    height: 2.5vw;
    position: relative;
}
.nav_item2{
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    overflow: hidden;
    width: 3vw;
    height: 1.23vw;
    position: relative;
    margin-bottom: 1vw;
}

.canvas-nav{
    left: unset;
    top: unset;
    right: 0;
    bottom: 0;
    /* width: unset !important;
    height: 100% !important; */
}
.canvas-nav2{
    left: 0;
    top: 0;
}
.projectsSlider{
        display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}

.canvas-projects{
    left: calc(50% - calc(100vh / 0.56 / 2));
    height: 100vh !important;
    width: calc(100vh / 0.56) !important;
    /* min-width: 100vw !important; */
    min-height: 100vh !important;
    cursor: default !important;
}
/* .canvas-projects{
    
    transition: 0.2s;
} */



/*

#projectSlider[data-sel="1"] .canvas-projects {
    left: 0;
    height: calc(100vh - 9%)!important;
    width: calc((100vh - 9%) * 0.67) !important;
    left: calc(50vw -  calc((100vh - 9%) * 0.67) / 2);
    top: 9%;
    left: 0;
    right: 0;
    margin: auto;
    height: -moz-fit-content !important;
    cursor: default !important;
}
.slide_back_im{
    position: fixed;
    top: -1vw;
    left: 21vw;
    width: 75vw;
    height: calc(44vw * 2);
    transform: rotate(2deg);
    opacity: 0;
    transition: 0.3s;
}
.slide_back_im.active{
    opacity: 1;
}
#projectSlider[data-sel="2"] .canvas-projects {
    left: 0;
    height: unset !important;
    width: 4vw !important;
    top: 20vw;
    left: 0vw;
    right: 0;
    margin: auto;
    height: -moz-fit-content !important;
    cursor: default !important;
}
#projectSlider[data-sel="3"] .canvas-projects {
    left: 0;
    height: 80vh !important;
    width: calc(80vh * 0.95) !important;
    top: 10vh;
    left: calc(50vw -  calc(80vh * 0.95) / 2);
    right: 0;
    height: -moz-fit-content !important;
    cursor: default !important;
}
*/

/* #projectSlider[data-sel="2"] .canvas-projects {
    left: 0;
    height: 60vw!important;
    width: 100vw !important;
    top: 0vh;
    left: 7vw;
    right: 0;
    margin: auto;
    height: -moz-fit-content !important;
    cursor: default !important;
} */

/* #projectSlider[data-sel="3"] .canvas-projects {
    left: 0;
    height: 100vh !important;
    width: 100vw !important;
    top: 0;
    left: 0;
    right: 0;
    height: -moz-fit-content !important;
    cursor: default !important;
} */
.defs, .slide, .canvas-projects {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}





@media (orientation: portrait) {
    .canvas-projects{
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
        min-width: 100% !important;
        min-height: 100% !important;
        cursor: default !important;
    }
    #projectSlider[data-sel="1"] .canvas-projects,
    #projectSlider[data-sel="2"] .canvas-projects,
    #projectSlider[data-sel="3"] .canvas-projects {
        top: unset !important;
        bottom: 50% !important;
        transform: translate(0, 50%);
        left: 0 !important;
        width: 100% !important;
        height: unset !important;
        min-width: 100% !important;
        min-height: 100% !important;
        cursor: default !important;
    }
    /* .canvas-projects{
        width: unset !important;
        height: 100vh !important;
        position: absolute;
        top: 0;
        left: calc(-140%);
    } */
}